<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/laboratory/banner.jpg')"></el-image>
        </div>
        <div class="appmain" style="margin-top:30px;">
            <div class="top">
                <el-row :gutter="60">
                    <el-col :span="12" :xs="24">
                        <div class="dianzhui"></div>
                        <div class="titles">试验站简介</div>
                        <div class="jieshao">
                            众智科技产品测试中心筹建于2010年，占地面积千余平方米。自组建以来，通过优化资源配置、改善试验环境、规范试验室管理、提升人员技术水平等措施，建立了全方位的测试体系，目前主要测试类型包括：EMC电磁兼容试验、环境类试验、失效分析试验、软硬件功能类测试。 
                        </div>
                        <div class="jieshao">
                            试验室自组建以来先后引进多种测试设备，目前设有电磁兼容试验室、环境应力试验室、失效分析实验室、发电机组和新能源储能试验室等。
                        </div>
                    </el-col>
                    <el-col :span="12" :xs="24">
                        <el-image :src="require('@/assets/laboratory/right.png')"></el-image>
                    </el-col>
                    <el-col :span="12" :xs="24">
                        <el-image :src="require('@/assets/laboratory/left.png')"></el-image>
                    </el-col>
                    <el-col :span="12" :xs="24">
                        <div class="jieshao" style="margin-top:80px;">
                            配置了近50台的先进设备，如暗室（预计2022年底建成）、频谱分析仪、金相试样切割机、高倍显微镜（1000倍）、晶体管图示仪、X射线荧光分析仪、步入式高低温湿热试验箱等，固定资产投入上千万元人民币，可用于开展多项电磁兼容试验、环境应力试验及失效分析试验。 
                        </div>
                        <div class="jieshao">
                            真机试验室仪器设备有柴油发电机组、静音发电机组、汽油发电机组等多种不同种类机组。可配合各类控制器进行操作并机、并市电、连接云平台、远程控制等测试。
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="testbz">
                <div class="bztitle">测试标准</div>
                <div>
                    <el-image style="width:100%" :src="require('@/assets/laboratory/biaozhun.png')"></el-image>
                </div>
            </div>
            <div class="testlx">
                <div class="lxtitle">试验类型</div>
                <div>
                    <el-row :gutter="30">
                        <el-col :span="12" :xs="24">
                            <el-image style="width:100%;cursor:pointer;" :src="require('@/assets/laboratory/zleft.png')" @click="nav('escr')"></el-image>
                        </el-col>
                        <el-col :span="12" :xs="24">
                            <el-image style="width:100%;cursor:pointer;" :src="require('@/assets/laboratory/zright1.png')" @click="nav('pfmea')"></el-image>
                            <el-image style="width:100%;margin-top:12px;cursor:pointer;" :src="require('@/assets/laboratory/zright2.png')" @click="nav('emc')"></el-image>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="testjs">
                <div class="jstitle">技术服务</div>
                <div>
                    <el-row :gutter="30">
                        <el-col :span="12" :xs="24">
                            <el-image style="width:100%" :src="require('@/assets/laboratory/sheji.png')"></el-image>
                        </el-col>
                        <el-col :span="12" :xs="24">
                            <el-image style="width:100%" :src="require('@/assets/laboratory/peixun.png')"></el-image>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
    methods:{
        nav(url){
            this.$router.push('/'+url,'_blank')
        }
    }
}
</script>
<style lang="scss" scoped>
    .appmain{
        .dianzhui{
            width: 54px;
            height: 4px;
            background: #0052D9;
        }
        .titles{
            margin-top:30px;
            font-size: 16px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #333333;
        }
        .jieshao{
            margin-top: 30px;
            font-size: 16px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
        }
        .testbz{
            margin-top: 40px;
            .bztitle{
                font-size: 24px;
                font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                font-weight: bold;
                color: #333333;
                text-align: center;
                margin-bottom: 40px;
            }
        }
        .testlx{
            margin-top: 40px;
            .lxtitle{
                font-size: 24px;
                font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                font-weight: bold;
                color: #333333;
                text-align: center;
                margin-bottom: 40px;
            }
        }
        .testjs{
            margin-top: 40px;
            .jstitle{
                font-size: 24px;
                font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                font-weight: bold;
                color: #333333;
                text-align: center;
                margin-bottom: 40px;
            }
        }
    }
</style>